import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import UUID from 'vue-uuid'
import VueAnalytics from 'vue-analytics';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.use(UUID);
Vue.use(VueMeta)
Vue.use(VueAnalytics, {
  id: 'UA-33833239-2'});
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
