<template>
  <v-app id="inspire">
    <v-app-bar
        app
        clipped-right
        flat
        height="72"
    >
      <p>devbites</p>
      <v-spacer></v-spacer>

      <v-responsive max-width="156">
        <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
        ></v-text-field>
      </v-responsive>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        app
        width="300"
    >
      <v-sheet
          color="grey lighten-5"
          height="128"
          width="100%"
      >

      </v-sheet>

      <v-list
          class="pl-4"
          shaped
      >
        <v-list>
          <v-list-item
              v-for="k in blogKeys"
              :key="k"
              link
          >
            <v-list-item-content>
              <v-list-item-title>{{ k.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
        app
        clipped
        right
    >
    </v-navigation-drawer>

    <v-main>
     <div class="text-center">
      <v-btn
          class="ma-2"
          color="success"
          @click="generateUUID()"
      >
        Generate UUID
      </v-btn>
       <h3 v-if="uuidVal">{{uuidVal}}</h3><p v-if="uuidVal" class="text-sm-caption"> Copied to Clipboard</p>
     </div>
    </v-main>

    <v-footer
        app
        color="transparent"
        height="72"
        inset
    >
      <v-text-field
          background-color="grey lighten-1"
          dense
          flat
          hide-details
          rounded
          solo
      ></v-text-field>
    </v-footer>
  </v-app>
</template>

<script>
import { uuid } from 'vue-uuid' // Import uuid

export default {
  name: 'App',
  components: {
  },
  props: {

  },
  data() {
    return {
      uuidVal: '',
      drawer: null,
      loading: false,
      blogKeys: [
        {
          name: null
        }
      ],
      blogs: [
        {
          title: null,
          desc: null,
          summary: null
        }
      ]
    }
  },
  created() {
    this.fetchBlogs()
  },
  methods: {
    generateUUID() {
      this.uuidVal = uuid.v1()
      navigator.clipboard.writeText(this.uuidVal);
      this.$ga.event('Tools', 'action', 'UUID', 1)
      setTimeout(()=>{ this.uuidVal = ''; }, 30000);
    },
    fetchBlogs() {
      this.loading = true
       fetch('https://tech-blogs.letzretro.workers.dev/blogs')
    .then(rb => {
        const reader = rb.body.getReader()
        return new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              // "done" is a Boolean and value a "Uint8Array"
              reader.read().then( ({done, value}) => {
                // If there is no more data to read
                if (done) {
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                // Check chunks by logging to the console
                push();
              })
            }
            push();
          }
        });
      })
     .then(stream => {
       return new Response(stream, { headers: { "Content-Type": "application/json" } }).text();
     })
     .then(result => {
       JSON.parse(result);
       //this.blogKeys = JSON.parse(result);
     });
    }
  },
  metaInfo () {
    return {
      title: "devbites - simple utility tools for software developers",
      meta: [
        {name: "description", vmid: "description", content: "devbites provide simple and easy utility for software developers. Tools like GUID UUID generator."},
        {property: 'og:title', content: "devbites - simple tools for software developers"},
        {property: 'og:site_name', content: 'devbites'},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  },
  mounted() {
    this.$ga.page('/home');
  }
};
</script>
